<template>
    <div class="seting">
        <BackBar :pageTit="headTit" />
        <div class="choseSex">
            <van-radio-group v-model="radio">
                <van-radio name="1" class="choseLi">
                    男
                    <template #icon="props">
                        <img class="img-icon" :src="props.checked ? activeIcon : inactiveIcon" />
                    </template>
                </van-radio>
                <van-radio name="2" class="choseLi">
                女
                    <template #icon="props">
                        <img class="img-icon" :src="props.checked ? activeIcon : inactiveIcon" />
                    </template>
                </van-radio>
                <van-radio name="0" class="choseLi">
                保密
                    <template #icon="props">
                        <img class="img-icon" :src="props.checked ? activeIcon : inactiveIcon" />
                    </template>
                </van-radio>
            </van-radio-group>
        </div>
        <div class="btn sure_btn" @click="sureBtn">保存</div>
        <div class="btn cancel_btn" @click="$router.back(-1)">取消</div>
    </div>
</template>
<script>
// @ is an alias to /src
import BackBar from "@/components/BackBar.vue";
import Api from '@/api'
export default {
    name:'Nickname',
    data(){
        return{
            headTit:'修改性别',
            radio: '1',
            activeIcon: require('../assets/img/choseOn.png'),
            inactiveIcon: require('../assets/img/chose.png'),
        }
    },
     mounted(){
        var that=this
        Api.go('my_goal',false,(res)=>{
            
            that.radio=res.me.sex
        })
    },
    components: {
        BackBar,
        require
    },
    methods:{
        sureBtn(){
             var that=this;
                    Api.go('change_info',{
                        type:'sex',
                        v:this.radio
                    },()=>{

                        that.$router.back(-1)
                    })
        }
    }
}
</script>
<style lang="less" scoped>
    .seting{
        .choseSex{
            background: #fff;
            padding: 0 .32rem;
            font-size: .32rem;
            .choseLi{
                padding: .32rem 0;
                border-bottom: 1px solid rgba(0, 0, 0, .1);
                font-size: .28rem;
                color: #333;
                .img-icon{
                    width: .32rem;
                    height: .32rem;
                }
                &:last-child{
                    border-bottom: none;
                }
            }
        }
        .btn{
            width: 6.86rem;
            height: .96rem;
            line-height: .96rem;
            border-radius: .48rem;
            font-size: .32rem;
            text-align: center;
            margin: 0 auto;
            &.sure_btn{
                background: #F65102;
                color: #fff;
                margin: .48rem auto .32rem;    
            }
            &.cancel_btn{
                background: rgba(0, 0, 0, .1);
                color: #333;
            }
        }
    }
</style>